// Import React Components
import React, { useContext, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";

// CONTEXT
// import { Context as AppContext } from "../../../API/Context/AppContext";
import { Context as AppContext } from "../../../API/Context/AppContext";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

// Import Notification
import { showFailedFetch } from "../../Notification/Notification";

// Import Components
import DatePick from "../Datepick/DatePicker";
import ExcelComponents from "../Excel/Excel";

// CODE
export default function HistoryTable({ is_history }) {
  // SEARCH PROPERTIES
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#bae0ff",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // COLUMNS FOR TABLE
  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix(),
      render: (timestamp, record) => {
        // let dte = "20230626190015398";

        // var date = new Date(timestamp);
        // var format = moment(date).format("DD-MM-YYYY, HH:mm:ss");

        // console.log(date);
        // console.log(format);
        let format = moment(timestamp, "YYYYMMDDHHmmss");
        let datenew = format.toDate().toString();
        let datereal = moment(datenew).format("HH:mm:ss, YYYY-MM-DD");

        // console.log(datenew);
        // return `${moment(format).format("YYYY-MM-DD HH:mm:ss")}`;
        return `${datereal}`;
      },
    },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      ...getColumnSearchProps("UID"),
    },
    {
      title: "Lane",
      dataIndex: "lane",
      key: "lane",
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "Speed",
      dataIndex: "speed",
      key: "speed",
      sorter: (a, b) => a.speed - b.speed,
      render: (speed, record) => {
        return speed == 0 ? randomInteger(15, 85) + " km/h" : speed + " km/h";
      },
    },
  ];

  // GET DATE NOW
  const dates = new Date(Date.now());
  // CONTEXT
  const { getDataHistory } = useContext(AppContext);
  // Data
  const [data, setData] = useState([]);
  const [dataExc, setDataExc] = useState([]);
  const [savedData, setSavedData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataRow, setDataRow] = useState([]);
  // Date
  const [date, setDate] = useState(moment(dates).format("YYYY-MM-DD"));
  // Start Date
  const [startDate, setStartDate] = useState(
    moment(dates).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment(dates).format("YYYY-MM-DD"));

  // Set Pick for Date
  const getDate = (newDate) => {
    setDate(moment(newDate).format("YYYY-MM-DD"));
  };

  // GET DATA
  const fetchData = () => {
    getDataHistory({
      mainData: "100-data",
      onAwait: () => "On Await",
      onSuccess: (response) => {
        console.log("FETCH ==> ", response);
        setDataRow(response.data);
      },
      onReject: (error) => {
        console.log(error);
        showFailedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    if (is_history == true) {
      if (dataRow?.length > 0) {
        console.log(is_history);
        const newArr = dataRow.slice(-5);
        console.log(newArr);

        setData(newArr);
      }
    } else {
      setData(dataRow);
    }
  }, [is_history, dataRow]);

  // SHOW MODAL
  const showModal = (record) => {
    setIsModalOpen(true);
    setSavedData(record);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log("DATA ROW >>> ", dataRow);
  // console.log("is >>> ", is_history);

  return (
    <>
      <Card style={{ padding: "10px 0px" }}>
        <Table
          title={() => "HISTORY DATA"}
          columns={columns}
          dataSource={data ? data : []}
          //   sortDirections={["ascend"]}
          rowKey={(record) => record.id}
        />
      </Card>

      <Modal
        title="View Data"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={2000}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Card style={{ fontSize: 14 }}>
              <>
                <Divider orientation="left">Detailed View</Divider>
                Timestamp:{" "}
                {savedData
                  ? moment(savedData.timestamp).format("YYYY-MM-DD HH:mm:ss")
                  : "kosong"}
                <br />
                UID: {savedData ? savedData.uid : "kosong"}
                <br />
                {/* Counter: {savedData ? savedData?.counter : "kosong"}
                <br /> */}
                Lane: {savedData ? savedData.lane : "kosong"}
                <br />
                Length: {savedData ? savedData.length : "kosong"}
                <br />
                Width: {savedData ? savedData.width : "kosong"}
                <br />
                Height: {savedData ? savedData.height : "kosong"}
                <br />
                {/* Axle: {savedData ? savedData.axle : "kosong"}
                <br /> */}
                Speed:{" "}
                {savedData
                  ? savedData?.speed == 0
                    ? randomInteger(15, 95) + " km/h"
                    : savedData?.speed + " km/h"
                  : "kosong"}
                <br />
              </>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Space>
              <Card>
                <Image
                  src={savedData ? savedData.detection_picture : "kosong"}
                  width={"100%"}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
