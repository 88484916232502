// IMPORT REACTS COMPONENTS
import React from "react";
import { useNavigate } from "react-router-dom";

// IMPORT ANT DESIGN COMPONENTS
import {
  Button,
  Card,
  Checkbox,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { LockTwoTone, SmileTwoTone, UserOutlined } from "@ant-design/icons";
// import { showLoginSuccess } from "../../Components/Notification/Notification";
// import { showLoginFailed, showLoginSuccess } from "../../Components/Notification/Notification";
// import {
//   showLoginFailed,
//   showLoginSuccess,
// } from "../../Components/Notification/Notification";

// FORM LAYOUT
const formItemLayout = {
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 24,
  },
};

// CODE
export default function Login() {
  // USE NAVIGATE
  const navigate = useNavigate();

  // CREATE USER
  let admin = {
    email: "hartama",
    password: "hartamalidar",
  };

  let user = {
    email: "user",
    password: "useranpr",
  };

  // HANDLE LOGIN
  const handleLogin = async (e) => {
    console.log(e);

    if (e.username == admin.email && e.password == admin.password) {
      const token = "QWERTYUIOP=";
      const admin = true;
      sessionStorage.setItem("auth-token", token);
      sessionStorage.setItem("admin", admin);
      //   showLoginSuccess(e);

      navigate("/");
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } else if (e.username == user.email && e.password == user.password) {
      const token = "QWERTYUIOP==";
      const admin = false;
      sessionStorage.setItem("auth-token", token);
      sessionStorage.setItem("admin", admin);
      //   showLoginSuccess(e);

      navigate("/");
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    } else {
      //   showLoginFailed(e);
    }
  };

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
          backgroundImage: "url(lidar.jpeg)",
          backgroundRepeat: "initial",
          backgroundSize: "100%",
        }}
      >
        <Content>
          <Row
            align="middle"
            justify="center"
            style={{
              display: "flex",
              flexDirection: "row",
              minHeight: "100vh",
              width: "100%",
            }}
          >
            <Card
              type="flex"
              align="middle"
              hoverable={true}
              style={{
                zIndex: 1,
                placeContent: "center",
                backgroundColor: "#fff1b8",
                padding: "40px 50px 20px 50px",
                width: 400,
                borderRadius: 15,
              }}
            >
              <Space
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <UserOutlined style={{ fontSize: 25 }} />
                <Typography.Title level={4}>LOGIN</Typography.Title>
              </Space>
              <Image
                src="HRU_02.png"
                width={"50%"}
                height={"100%"}
                preview={false}
                style={{
                  paddingBottom: 50,
                }}
              />
              <Form
                name="basic-login"
                {...formItemLayout}
                initialValues={{
                  remember: true,
                }}
                onFinish={handleLogin}
                layout="vertical"
                // onFinishFailed={handleFailed}
                autoComplete="off"
                style={{
                  fontSize: 20,
                  fontWeight: "bolder",
                  width: "100%",
                }}
              >
                <Form.Item
                  label="Username"
                  name={"username"}
                  rules={[
                    {
                      required: true,
                      message: "Please, input your username!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input
                    prefix={<SmileTwoTone />}
                    placeholder="Username"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please, input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockTwoTone />}
                    placeholder="Password"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  className="remember"
                  name={"remember"}
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 0,
                    span: "100%",
                  }}
                >
                  <Checkbox>Remember Me</Checkbox>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: "100%",
                  }}
                >
                  <Button htmlType="submit" type="primary" size="large">
                    LOGIN
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
