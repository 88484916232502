// Import React's Components
import React from "react";

// IMPORT ANT DESIGN COMPONENTS
import { Typography } from "antd";
import { Footer } from "antd/es/layout/layout";

// CODE
export default function FooterComponents() {
  return (
    <>
      <Footer
        style={{
          position: "sticky",
          // height: "8%",
          textAlign: "center",
          backgroundColor: "#001529",
        }}
      >
        <Typography style={{ color: "#FFFFFF", fontWeight: "bolder" }}>
          CV. HAZANI RANGKA UTAMA | @2024
        </Typography>
      </Footer>
    </>
  );
}
