// Import React's Components
import React, { useState } from "react";

//  Import Ant Design's Components
import { Col, Image, Layout, Menu } from "antd";
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  HistoryOutlined,
  SettingOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

// Import Page Components
import Navigation from "../Routes/Navigation";
import Login from "../../Pages/Login/Login";
import { useNavigate } from "react-router-dom";
import FooterComponents from "./Footer";
import HeaderComponents from "./Header";

// Layout Components
const { Header, Content, Footer, Sider } = Layout;

// Create Menu Side Drawer
const menuItems = [
  {
    label: "Live Monitoring",
    key: "/",
    icon: <VideoCameraOutlined />,
  },
  {
    label: "Analytics",
    key: "/analytics",
    icon: <AppstoreOutlined />,
  },
  {
    label: "History",
    key: "history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Settings",
    key: "status",
    icon: <SettingOutlined />,
  },
];

const menuItemsUser = [
  {
    label: "Live Monitoring",
    key: "/",
    icon: <VideoCameraOutlined />,
  },
  {
    label: "Analytics",
    key: "/analytics",
    icon: <AppstoreOutlined />,
  },
  {
    label: "History",
    key: "history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Status",
    key: "status",
    icon: <CheckCircleOutlined />,
  },
];

// CODE
export default function LayoutPage() {
  // STATE MANAGEMENT
  const [collapse, setCollapse] = useState(false);

  // GET ACCESS TOKEN
  const token = sessionStorage.getItem("auth-token");
  const admin = sessionStorage.getItem("admin") == "true" ? true : false;

  // USE NAVIGATE
  const navigate = useNavigate();

  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  const onBreakpoint = (broken) => {
    console.log("BROKEN ==>> ", broken);
    setCollapse(broken);
  };

  // ON CLICK NAVIGATE TO
  const navigation = (e) => {
    // console.log(e);
    navigate(`${e.key}`);
  };

  console.log(collapse);

  return (
    <>
      {token ? (
        <Layout
          style={{
            minHeight: "87vh",
            backgroundColor: "#bfbfbf",
          }}
        >
          <Sider
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={onBreakpoint}
            onCollapse={(collapse, type) => {
              console.log("COLLAPSE = " + collapse + " & TYPE = " + type);
            }}
            style={{
              textAlign: "left",
              position: "fixed",
              zIndex: 100,
              height: "100%",
            }}
          >
            <Col
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <Image
                src="HRU_02.png"
                preview={false}
                //  width={"50%"}
                height={90}
                // style={{ position: "center" }}
              />
            </Col>

            <Menu
              theme="dark"
              mode="vertical"
              items={admin ? menuItems : menuItemsUser}
              onClick={navigation}
              style={{
                // fontSize: 25,
                fontWeight: "bolder",
                paddingTop: 20,
              }}
            />
          </Sider>

          <Layout style={{ backgroundImage: "url(lidar.jpeg)" }}>
            <Header
              style={{
                position: "fixed",
                zIndex: 1,
                width: "100%",
                marginLeft: "200px",
                paddingBottom: 0,
                paddingInline: 0,
                height: 91,
              }}
            >
              <HeaderComponents />
            </Header>

            <Content
              style={{
                margin: "24px  16px 0 200px",
              }}
            >
              <Navigation />
            </Content>

            <FooterComponents />
          </Layout>
        </Layout>
      ) : (
        <Layout>
          <Login />
        </Layout>
      )}
    </>
  );
}
